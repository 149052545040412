/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/indigo-pink.css';

html, body { height: 100%; padding: 0;}
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif;
    padding: 0;
    .b2b-btn-color {
        background-color: #9379D1;
    }

    .b2b-pink {
        color: #9379D1
    }
}

.grecaptcha-badge { visibility: hidden; }